import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Space, Table} from "antd";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import {doRestCall} from "../../AppUtils";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";

const TournamentRegistrationClosed = ({tournament}) => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    const navigate = useNavigate();

    let {tournamentId} = useParams();
    const { currentUser } = useCurrentUserContext();

    const [loading, setLoading] = useState(true)

    const onEdit = () => {
        navigate("/tournament/edit/" + tournament.id)
    };

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '60%',
        render: (_, row) => (row.user.username)

    }, {
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
    }];

    function navigateBack() {
        navigate("/tournaments")
    }

    function onConfirmDelete() {
        doRestCall( '/tournament/delete/' + tournament.id, 'delete', null, null, navigateBack)
    }

    return (
        <>
            <Auth/>

            <Space className="tournTableInfo" direction={"vertical"} >

                {(tournament.players.length === 0) &&
                    <>
                        <h2 className="tournTableInfoTitles">Participants:</h2>
                        <div className="tournViewNoTable"> No Participants Found</div>
                    </>
                }

                {(tournament.players.length > 0) && (
                    <>
                        <h2 className="tournTableInfoTitles">Participants:</h2>
                        <div className="tournViewTable">
                            <Table
                                columns={usersTableColumns}
                                dataSource={tournament.players}
                                pagination={
                                    {
                                        pageSize: 10
                                    }
                                }
                                id={"usersTable"}
                                style={{width: '100%', minWidth: '42rem'}}
                                showHeader={true}
                            />
                        </div>
                    </>
                )}

            { currentUser && (currentUser.role === 'ADMIN' || currentUser.id === tournament.ownerId || currentUser.tournamentMasterId)
                && (
                    <div className="tournViewButtons">
                        <Button  onClick={navigateBack}>Back</Button>
                        <Space direction={"horizontal"}>
                            <EditButton tournamentId={tournament.id} ownerId={tournament.id} tournamentMasterId={tournament.tournamentMasterId}  />
                            <DeleteButton tournamentId={tournament.id} ownerId={tournament.ownerId} />
                        </Space>
                    </div>
                )
            }

            </Space>

        </>
    );
};

export default TournamentRegistrationClosed;

