import './App.css';

import {BrowserRouter, Route, Router, Routes} from "react-router-dom";
import MainMenu from "./menu/MainMenu";
import {CurrentUserContextProvider, useCurrentUserContext} from "./user/CurrentUserContext";
import ReactGA from "react-ga4";
import Footer from "./home/Footer";
import React from "react";
import RestUsage from "./system/restusage/RestUsage";
import Health from "./system/Health";
import Home from "./home/Home";
import RosterCreate from "./roster/create/RosterCreate";
import RosterEdit from "./roster/edit/RosterEdit";
import RostersAll from "./roster/all/RostersAll";
import RostersMy from "./roster/my/RostersMy";
import RosterView from "./roster/view/RosterView";
import GameCreate from "./game/create/GameCreate";
import Games from "./game/all/Games";
import GameView from "./game/view/GameView";
import GameEdit from "./game/edit/GameEdit";
import UserRegistration from "./user/register/UserRegistration";
import UsersAll from "./user/all/UsersAll";
import UserCreate from "./user/create/UserCreate";
import UserLogin from "./user/login/UserLogin";
import UserLogout from "./user/logout/UserLogout";
import UserAccount from "./user/account/UserAccount";
import TournamentCreate from "./tournament/create/TournamentCreate";
import Tournaments from "./tournament/all/Tournaments";
import TournamentView from "./tournament/view/TournamentView";
import TournamentBoard from "./tournament/board/TournamentBoard";
import TournamentEdit from "./tournament/edit/TournamentEdit";
import TagsMy from "./tag/my/TagsMy";
import AssignUnit from "./tag/assignunit/AssignUnit";
import CodexUnits from "./codex/unit/CodexUnits";
import ForceCreate from "./crusade/force/create/ForceCreate";
import ForceView from "./crusade/force/view/ForceView";
import ForceManage from "./crusade/force/manage/ForceManage";
import ForcePrint from "./crusade/force/print/ForcePrint";
import CardCreate from "./crusade/card/create/CardCreate";
import MyForces from "./crusade/force/my/MyForces";
import CardManage from "./crusade/card/manage/CardManage";
import CrusadeRosterCreate from "./crusade/roster/create/CrusadeRosterCreate";
import CrusadeRosterView from "./crusade/roster/view/CrusadeRosterView";
import CrusadeRosterEdit from "./crusade/roster/edit/CrusadeRosterEdit";
import CrusadeRostersAll from "./crusade/roster/all/CrusadeRostersAll";
import CrusadeRostersMy from "./crusade/roster/my/CrusadeRostersMy";
import Forces from "./crusade/force/all/Forces";
import MobileNotSupported from "./home/MobileNotSupported";

function App() {

    ReactGA.initialize("G-GG4M498TS6");

    const queryParams = new URLSearchParams(window.location.search)
    const ref = queryParams.get("ref")

    return (
        <div className="App">
            <BrowserRouter>
                <CurrentUserContextProvider>

                    <MainMenu/>

                    <Routes>

                        <Route path="/system/usage/rest" element={<RestUsage/>}/>
                        <Route path="/system/health" element={<Health/>}/>

                        <Route path="/mobilenotsupported" element={<MobileNotSupported/>}/>

                        <Route path="/home" element={<Home/>}/>
                        <Route path="/" element={<Home/>}/>

                        <Route path="/roster/create" element={<RosterCreate/>}/>
                        <Route path="/roster/edit/:rosterId" element={<RosterEdit/>}/>
                        <Route path="/rosters/all" element={<RostersAll/>}/>
                        <Route path="/rosters/my" element={<RostersMy/>}/>
                        <Route path="/roster/view/:rosterId" element={<RosterView/>}/>

                        <Route path="/game/create" element={<GameCreate/>}/>
                        <Route path="/games" element={<Games/>}/>
                        <Route path="/game/view/:gameId" element={<GameView/>}/>
                        <Route path="/game/edit/:gameId" element={<GameEdit/>}/>

                        <Route path="/user/registration" element={<UserRegistration/>}/>
                        <Route path="/users/all" element={<UsersAll/>}/>
                        <Route path="/user/create" element={<UserCreate/>}/>
                        <Route path="/user/login" element={<UserLogin/>}/>
                        <Route path="/user/logout" element={<UserLogout/>}/>
                        <Route path="/user/account" element={<UserAccount/>}/>

                        <Route path="/tournament/create" element={<TournamentCreate/>}/>
                        <Route path="/tournaments" element={<Tournaments/>}/>
                        <Route path="/tournament/view/:tournamentId" element={<TournamentView/>}/>
                        <Route path="/tournament/board/:tournamentId" element={<TournamentBoard/>}/>
                        <Route path="/tournament/edit/:tournamentId" element={<TournamentEdit/>}/>

                        <Route path="/tags/my" element={<TagsMy/>}/>
                        <Route path="/tag/assign/unit" element={<AssignUnit/>}/>

                        <Route path="/codex/units" element={<CodexUnits/>}/>

                        <Route path="/crusade/force/create" element={<ForceCreate/>}/>
                        <Route path="/crusade/force/:forceId/view" element={<ForceView/>}/>
                        <Route path="/crusade/force/:forceId/manage" element={<ForceManage/>}/>
                        <Route path="/crusade/force/:forceId/print" element={<ForcePrint/>}/>
                        <Route path="/crusade/force/:forceId/unit/create" element={<CardCreate/>}/>
                        <Route path="/crusade/forces/my" element={<MyForces/>}/>
                        <Route path="/crusade/forces" element={ <Forces /> }  />


                        <Route path="/crusade/card/create" element={<CardCreate/>}/>
                        <Route path="/crusade/force/:forceId/card/:cardId/manage" element={<CardManage/>}/>

                        <Route path="/crusade/roster/create" element={<CrusadeRosterCreate/>}/>
                        <Route path="/crusade/roster/view/:crusadeRosterId" element={<CrusadeRosterView/>}/>
                        <Route path="/crusade/roster/edit/:crusadeRosterId" element={<CrusadeRosterEdit/>}/>
                        <Route path="/crusade/rosters/all" element={<CrusadeRostersAll/>}/>
                        <Route path="/crusade/rosters/my" element={<CrusadeRostersMy/>}/>

                    </Routes>

                    <Footer/>

                </CurrentUserContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
