import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Space, Table} from "antd";
import moment from "moment/moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import {doRestCall} from "../../AppUtils";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";

const TournamentStarted = ({tournament}) => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);
    const [games, setGames] = useState([]);

    const { currentUser } = useCurrentUserContext();

    useEffect(() => {
        if (tournament === undefined) {
            return;
        }

        let tournamentGames = tournament.games;

        if (tournamentGames === undefined || tournamentGames === null || tournamentGames.length === 0) {

        } else {

            for (let i = 0; i < tournamentGames.length; i++) {
                tournamentGames[i] = {
                    id: tournamentGames[i].id,
                    name: tournamentGames[i].name,
                    value: tournamentGames[i].id,
                    label: tournamentGames[i].name,
                    key: tournamentGames[i].key,
                    winner: tournamentGames[i].gameWinnerUsername === 'None' ? '' : tournamentGames[i].gameWinnerUsername,
                    date: tournamentGames[i].dateTime && moment(tournamentGames[i].dateTime, 'YYYYMMDD').format('DD MMM YYYY'),
                }
            }

            setGames(tournamentGames)
        }

        //

        let tournamentPlayers = tournament.players;

        if (tournamentPlayers === undefined || tournamentPlayers === null || tournamentPlayers.length === 0) {

        } else {

            for (let i = 0; i < tournamentPlayers.length; i++) {
                tournamentPlayers[i] = {
                    id: tournamentPlayers[i].user.id,
                    name: tournamentPlayers[i].user.username,
                    value: tournamentPlayers[i].user.id,
                    label: tournamentPlayers[i].user.username,
                    key: tournamentPlayers[i].user.key,
                    score: tournamentPlayers[i].score,
                    wins: tournamentPlayers[i].stats.wins,
                    draws: tournamentPlayers[i].stats.draws,
                    losses: tournamentPlayers[i].stats.losses,
                    faction: tournamentPlayers[i].faction
                }
            }

            setPlayers(tournamentPlayers.sort((p1, p2) => p2.score - p1.score))
        }
    }, [tournament]);


    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '40%',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '30%',
    },{
        title: 'Score', dataIndex: 'score', key: 'key', width: '15%',
    },{
        title: 'Wins', dataIndex: 'wins', key: 'key', width: '5%',
    },{
        title: 'Draws', dataIndex: 'draws', key: 'key', width: '5%',
    },{
        title: 'Losses', dataIndex: 'losses', key: 'key', width: '5%',
    }];

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '50%',
    },{
        title: 'Winner', dataIndex: 'winner', key: 'winner', width: '25%',
    },{
        title: 'Date', dataIndex: 'date', key: 'date', width: '25%',
    },];

    const onEdit = () => {
        navigate("/tournament/edit/" + tournament.id)
    };

    const onBoard = () => {
        navigate("/tournament/board/" + tournament.id)
    };

    function navigateBack() {
        navigate("/tournaments")
    }

    function onConfirmDelete() {
        doRestCall( '/tournament/delete/' + tournament.id, 'delete', null, null, navigateBack)
    }

    return (
        <>
            <Auth />
                <Space direction="vertical" className="tournView">

                    { (players.length === 0) &&
                      <>
                        <h2 className="tournTableInfoTitles">Participants:</h2>
                        <div className="tournViewNoTable"> No Participants Found </div>
                      </>
                    }
                    { (players.length > 0) && <Form.Item name="usersFormItem">
                        <h2 className="tournTableInfoTitles">Participants:</h2>
                        <div className="tournViewTable">
                          <Table
                              columns={usersTableColumns}
                              dataSource={players}
                              pagination={
                                {
                                  pageSize: 10
                                }
                              }
                              id={"usersTable"}
                              style={{width: '100%', minWidth: '42rem'}}
                              showHeader={true}
                          />
                        </div>
                    </Form.Item>
                    }

                    { (games.length === 0) && <>
                        <h2 className="tournTableInfoTitles">Games:</h2>
                        <div className="tournViewNoTable"> No Games Found </div>
                      </> }
                    { (games.length > 0) && <Form.Item name="gamesFormItem">
                        <h2 className="tournTableInfoTitles">Games:</h2>
                        <div className="tournViewTable">
                          <Table
                              columns={gamesTableColumns}
                              dataSource={games}
                              pagination={
                                {
                                  pageSize: 10
                                }
                              }
                              id={"gamesTable"}
                              style={{width: '100%', minWidth: '42rem'}}
                              showHeader={true}
                          />
                        </div>
                    </Form.Item>
                    }

                    <Space className="tournViewButtons" direction={"horizontal"}>
                        <Button onClick={navigateBack}>
                            Back
                        </Button>

                        <div>
                            <Button onClick={onBoard}>Board</Button>
                            <Space direction={"horizontal"}>
                                <EditButton tournamentId={tournament.id} ownerId={tournament.id} tournamentMasterId={tournament.tournamentMasterId}  />
                                <DeleteButton tournamentId={tournament.id} ownerId={tournament.ownerId} />
                            </Space>
                        </div>
                    </Space>
                </Space>

         </>
    );
};

export default TournamentStarted;

