import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Space, Table, Typography} from "antd";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import TournamentStarted from "./TournamentStarted";
import TournamentRegistrationOpen from "./TournamentRegistrationOpen";
import TournamentRegistrationClosed from "./TournamentRegistrationClosed";
import TournamentEnded from "./TournamentEnded";
import TournamentCreated from "./TournamentCreated";
import Status from "./components/Status";
import Details from "./components/Details";
import {doRestCall} from "../../AppUtils";

const TournamentView = () => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    let {tournamentId} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});
    const { Title } = Typography;

    useEffect(() => {
        load()
    }, [])

    const onTournamentUpdate = () => {
        load()
    }


    function onViewSuccess(response) {
        setTournament(response.body)

        setLoading(false)
    }

    const load = () => {
        doRestCall( '/tournament/view/' + tournamentId, 'get', null, null, onViewSuccess)
    }

    return (
        <>
            <Auth/>

            <Space><Title level={2} >Tournament</Title> <Title level={2} > <span data-cy="name">{tournament.name}</span></Title></Space>

            <Space direction="vertical" style={{width: '100%'}}>
                <Status status={tournament.status}/>
                <Details
                    status={tournament.status}
                    regStartDate={tournament.registrationStartDate}
                    regEndDate={tournament.registrationEndDate}
                    startDate={tournament.startDate}
                    endDate={tournament.endDate}
                    master={tournament.tournamentMaster}
                    universe={tournament.universe}
                    tournamentId={tournament.id}
                    onTournamentUpdate={onTournamentUpdate}
                />
                {(tournament && tournament.status === 'REGISTRATION_OPENED') &&  <TournamentRegistrationOpen tournament={tournament}/>}
                {(tournament && tournament.status === 'REGISTRATION_CLOSED') && <TournamentRegistrationClosed tournament={tournament}/>}
                {(tournament && tournament.status === 'STARTED') && <TournamentStarted tournament={tournament}/>}
                {(tournament && tournament.status === 'CREATED') && <TournamentCreated tournament={tournament}/>}
                {(tournament && tournament.status === 'ENDED') && <TournamentEnded tournament={tournament}/>}

            </Space>
        </>
    );
};

export default TournamentView;

