import React, {useEffect, useState} from "react";
import "../styles.css";

import {newsWC} from "./NewsConnector";
import { Image, List, Typography} from 'antd';
import * as sqrl from "squirrelly";
import Link from "antd/lib/typography/Link";
import moment from "moment/moment";

const News = () => {
    const [loading, setLoading] = useState(true)
    const [news, setNews] = useState({});

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        newsWC()
            .then(response => {
                setNews(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }


    const htmlNode = (text) =>{
        /**
         *we are formating a data in the particular format so that
         *we can use it along with squirrelly templating
         */
        const data  = {data : text}
        /**
         * In the below code we are ,merging the html string and data together
         */
        const __html = sqrl.render(text, data || []);
        return <div dangerouslySetInnerHTML={{__html}}/>
    }


    return (
        <>
            {loading && <p>loading</p>}
            {!loading &&

                <List
                    className="newsList"
                    itemLayout="vertical"
                    grid={{ column: 2 }}
                    bordered
                    pagination={true}
                    dataSource={news}
                    renderItem={(item) => (
                        <List.Item id="newsListItem">
                            <Image className="newsListImg" src={item.imageUrl} preview={false} width={'100%'} />
                            <Link href={item.url}>
                                <Typography.Title className="newsTitle"level={3}>
                                    {item.title}
                                </Typography.Title>
                            </Link>
                            <Typography.Paragraph className="publishedDate">
                                Published: {moment(item.publishingDate, 'YYYYMMDDhhmm').format('DD-MMM-YYYY')}
                            </Typography.Paragraph>
                        </List.Item>
                    )}
                />

            }
        </>
    );
};

export default News;